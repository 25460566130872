export const tokens = 
[
    {"price":0.0382837436,"denom":"ibc/00B6E60AD3D65CBEF5579AC8AF609527C0B57535B6E32D96C80A735344FD9DCC","symbol":"RAW","main":false,"liquidity":1788.3720279772,"volume_24h":716.0204867531,"volume_24h_change":-25.1527531152,"name":"Junoswap","price_24h_change":-11.8462794553,"price_7d_change":-31.5216231667,"exponent":6},
    {"price":1.5243195188,"denom":"ibc/0954E1C28EB7AF5B72D24F3BC2B47BBB2FDF91BDDFD57B74B99E133AED40972A","symbol":"SCRT","main":true,"liquidity":3517343.3049269374,"volume_24h":757907.6661784109,"volume_24h_change":0.5340811727,"name":"Secret Network","price_24h_change":-8.8572985618,"price_7d_change":-15.6061795275,"exponent":6},
    {"price":0.9997942958,"denom":"ibc/0CD3A0285E1341859B5E86B6AB7682F023D03E97607CCC1DC95706411D866DF7","symbol":"axlDAI","main":true,"liquidity":2724872.2180766487,"volume_24h":404272.0468361689,"volume_24h_change":-4.6870335368,"name":"Dai Stablecoin","price_24h_change":-0.0075218397,"price_7d_change":0.3024868236,"exponent":18},
    {"price":0.965189,"denom":"ibc/0E43EDE2E2A3AFA36D0CD38BDDC0B49FECA64FA426A82E102F304E430ECF46EE","symbol":"axlFRAX","main":false,"liquidity":16954.4759341633,"volume_24h":28642.4854910297,"volume_24h_change":174.2989184466,"name":"Frax","price_24h_change":-0.0401832272,"price_7d_change":-1.8903501371,"exponent":18},
    {"price":7.40728e-05,"denom":"ibc/0EF15DF2F02480ADE0BB6E85D9EBB5DAEA2836D3860E9F97F9AADE4F57A31AA0","symbol":"LUNA","main":true,"liquidity":122922.6008077564,"volume_24h":358511.6456889615,"volume_24h_change":-36.1645609805,"name":"Luna","price_24h_change":-10.2015035514,"price_7d_change":-4.2997796993,"exponent":6},
    {"price":0.3970004148,"denom":"ibc/1480B8FD20AD5FCAE81EA87584D269547DD4D436843C1D20F15E00EB64743EF4","symbol":"AKT","main":true,"liquidity":2241554.924873025,"volume_24h":192132.8240155929,"volume_24h_change":59.8970845763,"name":"Akash Network","price_24h_change":-5.475715884,"price_7d_change":-11.2011296614,"exponent":6},
    {"price":0.7372978997,"denom":"ibc/1DC495FCEFDA068A3820F903EDBD78B942FBD204D7E93D3BA2B432E9669D1A59","symbol":"NGM","main":true,"liquidity":733786.3554750584,"volume_24h":106597.5854604798,"volume_24h_change":10.8074223993,"name":"e-Money","price_24h_change":-3.2373231401,"price_7d_change":-8.2317599013,"exponent":6},
    {"price":0.3079412558,"denom":"ibc/1DCC8A6CB5689018431323953344A9F6CC4D0BFB261E88C9F7777372C10CD076","symbol":"REGEN","main":true,"liquidity":657240.9087028443,"volume_24h":47916.3777883827,"volume_24h_change":8.3872916252,"name":"Regen Network","price_24h_change":-7.3879212248,"price_7d_change":4.3418895076,"exponent":6},
    {"price":9.3459016377,"denom":"ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2","symbol":"ATOM","main":true,"liquidity":61643464.836012326,"volume_24h":8440456.26128799,"volume_24h_change":-0.9384936128,"name":"Cosmos","price_24h_change":-8.6567765719,"price_7d_change":-3.6406154357,"exponent":6},
    {"price":77.3720643654,"denom":"ibc/297C64CC42B5A8D8F82FE2EBE208A6FE8F94B86037FA28C4529A23701C228F7A","symbol":"NETA","main":true,"liquidity":384907.2352091176,"volume_24h":43437.3679601046,"volume_24h_change":225.3113381708,"name":"Neta","price_24h_change":-8.4676910589,"price_7d_change":-19.4426647401,"exponent":6},
    {"price":0.0003968132,"denom":"ibc/307E5C96C8F60D1CBEE269A9A86C0834E1DB06F2B3788AE4F716EDB97A48B97D","symbol":"DIG","main":true,"liquidity":21556.075467603,"volume_24h":90.1410433537,"volume_24h_change":-58.2312513314,"name":"Dig Chain","price_24h_change":-7.5335563961,"price_7d_change":-7.7241843952,"exponent":6},
    {"price":0.0033184075,"denom":"ibc/346786EA82F41FE55FAD14BF69AD8BA9B36985406E43F3CB23E6C45A285A9593","symbol":"DARC","main":true,"liquidity":82611.906017175,"volume_24h":6294.6187024212,"volume_24h_change":17.6991206768,"name":"Konstellation","price_24h_change":-13.113412436,"price_7d_change":-33.8924264372,"exponent":6},
    {"price":0.0279507474,"denom":"ibc/3BCCC93AD5DF58D11A6F8A05FA8BC801CBA0BA61A981F57E91B8B598BF8061CB","symbol":"MED","main":true,"liquidity":642708.6818993709,"volume_24h":153082.7200470726,"volume_24h_change":189.0724861091,"name":"MediBloc","price_24h_change":-3.9290561179,"price_7d_change":7.0969912412,"exponent":6},
    {"price":3.9444e-06,"denom":"ibc/41999DF04D9441DAC0DF5D8291DF4333FBCBA810FFD63FDCE34FDF41EF37B6F7","symbol":"CRBRUS","main":true,"liquidity":8126.9054884378,"volume_24h":557.1534226801,"volume_24h_change":19.2833210078,"name":"Cerberus","price_24h_change":-9.83862104,"price_7d_change":-24.693277155,"exponent":6},
    {"price":4.9562065184,"denom":"ibc/46B44899322F3CD854D2D46DEEF881958467CDD4B3B10086DA49296BBED94BED","symbol":"JUNO","main":true,"liquidity":8569267.532239806,"volume_24h":2289918.2640603213,"volume_24h_change":100.1128358933,"name":"Juno","price_24h_change":-11.9231355666,"price_7d_change":-19.5323572645,"exponent":6},
    {"price":0.0172474523,"denom":"ibc/4E5444C35610CC76FC94E7F7886B93121175C28262DDFDDE6F84E82BF2425452","symbol":"BTSG","main":true,"liquidity":152985.6756942243,"volume_24h":8577.5859817616,"volume_24h_change":21.1128701801,"name":"BitSong","price_24h_change":-9.9140943375,"price_7d_change":-14.221611253,"exponent":6},
    {"price":0.0426684621,"denom":"ibc/52B1AA623B34EB78FD767CEA69E8D7FA6C9CFE1FBF49C5406268FD325E2CC2AC","symbol":"IOV","main":true,"liquidity":54341.7108524472,"volume_24h":5427.9474087302,"volume_24h_change":1.9349797945,"name":"Starname","price_24h_change":-2.2637502362,"price_7d_change":34.9092639882,"exponent":6},
    {"price":1.0060860003,"denom":"ibc/5973C068568365FFF40DEDCF1A1CB7582B6116B731CD31A12231AE25E20B871F","symbol":"EEUR","main":true,"liquidity":1663907.4629542697,"volume_24h":658263.7045684321,"volume_24h_change":50.7040605421,"name":"e-Money EUR","price_24h_change":-0.4279807895,"price_7d_change":0.6440015286,"exponent":6},
    {"price":0.1534328474,"denom":"ibc/5D1F516200EE8C6B2354102143B78A2DEDA25EDE771AC0F8DC3C1837C8FD4447","symbol":"FET","main":true,"liquidity":85570.8342020296,"volume_24h":4511.5401029947,"volume_24h_change":-21.7414112381,"name":"Fetch.ai","price_24h_change":-9.7579383852,"price_7d_change":5.0889755836,"exponent":18},
    {"price":1815.8178684024,"denom":"ibc/65381C5F3FD21442283D56925E62EA524DED8B6927F0FF94E21E0020954C40B5","symbol":"gWETH","main":false,"liquidity":140.9351176879,"volume_24h":34.1261714815,"volume_24h_change":396.8215828007,"name":"Wrapped Ethereum","price_24h_change":-5.8723738335,"price_7d_change":-10.2463603711,"exponent":18},
    {"price":0.4107143661,"denom":"ibc/655BCEF3CDEBE32863FF281DBBE3B06160339E9897DC9C9C9821932A5F8BA6F8","symbol":"TICK","main":false,"liquidity":38450.297121411,"volume_24h":1629.2184961185,"volume_24h_change":32.5011264104,"name":"Microtick","price_24h_change":-14.0130093729,"price_7d_change":-12.0954109582,"exponent":6},
    {"price":0.0250248572,"denom":"ibc/67795E528DF67C5606FC20F824EA39A6EF55BA133F4DC79C90A8C47A0901E17C","symbol":"UMEE","main":true,"liquidity":505593.5770499888,"volume_24h":81647.7176863164,"volume_24h_change":-48.7891193584,"name":"Umee","price_24h_change":-4.2456188353,"price_7d_change":-10.2504282569,"exponent":6},
    {"price":3.5158574265,"denom":"ibc/6AE98883D4D5D5FF9E50D7130F1305DA2FFA0C652D1DD9C123657C6B4EB2DF8A","symbol":"EVMOS","main":true,"liquidity":342313.3340297005,"volume_24h":2766005.0932267434,"volume_24h_change":56.3603312525,"name":"Evmos","price_24h_change":13.4473697109,"price_7d_change":92.2633376568,"exponent":18},
    {"price":0.1003919537,"denom":"ibc/6BDB4C8CCD45033F9604E4B93ED395008A753E01EECD6992E7D1EA23D9D3B788","symbol":"RAC","main":false,"liquidity":6655.8954770864,"volume_24h":261.7953122427,"volume_24h_change":-73.9159366605,"name":"Racoon","price_24h_change":-10.9053848165,"price_7d_change":-24.2635943135,"exponent":6},
    {"price":0.0425442166,"denom":"ibc/7A08C6F11EF0F59EB841B9F788A87EC9F2361C7D9703157EC13D940DC53031FA","symbol":"CHEQ","main":true,"liquidity":101966.2339285713,"volume_24h":78698.1567045064,"volume_24h_change":1050.8621906339,"name":"Cheqd","price_24h_change":-5.5967182003,"price_7d_change":-18.4983134841,"exponent":9},
    {"price":0.0232352951,"denom":"ibc/7C4D60AA95E5A7558B0A364860979CA34B7FF8AAF255B87AF9E879374470CEC0","symbol":"IRIS","main":true,"liquidity":325713.8864668731,"volume_24h":15451.3690595778,"volume_24h_change":-60.3301383207,"name":"IRISnet","price_24h_change":-7.1847060702,"price_7d_change":-1.5295317635,"exponent":6},
    {"price":0.2332152386,"denom":"ibc/8061A06D3BD4D52C4A28FFECF7150D370393AF0BA661C3776C54FF32836C3961","symbol":"PSTAKE","main":true,"liquidity":543847.1676982236,"volume_24h":9377.8280934125,"volume_24h_change":260.896715481,"name":"PSTAKE","price_24h_change":-6.0465763571,"price_7d_change":-11.2114845202,"exponent":18},
    {"price":0.9716055707,"denom":"ibc/8242AD24008032E457D2E12D46588FD39FB54FB29680C6C7663D296B383C37C4","symbol":"axlUSDT","main":true,"liquidity":16960.7547192375,"volume_24h":28644.0700932093,"volume_24h_change":174.3140936193,"name":"Tether USD","price_24h_change":0.5872296908,"price_7d_change":-1.1362242069,"exponent":6},
    {"price":0.0172378971,"denom":"ibc/8318FD63C42203D16DDCAF49FE10E8590669B3219A3E87676AC9DA50722687FB","symbol":"ROWAN","main":true,"liquidity":693006.1495209784,"volume_24h":125307.7584422356,"volume_24h_change":-6.366920778,"name":"Sifchain Rowan","price_24h_change":-15.7474012098,"price_7d_change":-26.5323460571,"exponent":18},
    {"price":0.0014923946,"denom":"ibc/8A34AF0C1943FD0DFCDE9ADBF0B2C9959C45E87E6088EA2FC6ADACD59261B8A2","symbol":"LUM","main":true,"liquidity":308471.7257377374,"volume_24h":35563.4468977324,"volume_24h_change":-5.1027521449,"name":"Lum","price_24h_change":-7.8805442503,"price_7d_change":-16.0993847996,"exponent":6},
    {"price":0.0056013252,"denom":"ibc/8FEFAE6AECF6E2A255585617F781F35A8D5709A545A804482A261C0C9548A9D3","symbol":"SWTH","main":true,"liquidity":90892.3302706324,"volume_24h":2509.196895675,"volume_24h_change":-34.5065527853,"name":"Carbon","price_24h_change":-4.9357637978,"price_7d_change":-2.9952607712,"exponent":8},
    {"price":0.0016708314,"denom":"ibc/9712DBB13B9631EDFA9BF61B55F1B2D290B2ADB67E3A4EB3A875F3B6081B3B84","symbol":"DVPN","main":true,"liquidity":1213170.2929249613,"volume_24h":189168.7020554644,"volume_24h_change":-80.7732379453,"name":"Sentinel","price_24h_change":-7.2119204763,"price_7d_change":44.7080309753,"exponent":6},
    {"price":0.0325869488,"denom":"ibc/987C17B11ABC2B20019178ACE62929FE9840202CE79498E29FE8E5CB02B7C0A4","symbol":"STARS","main":true,"liquidity":3222491.6693853047,"volume_24h":230172.9779135467,"volume_24h_change":42.53646277,"name":"Stargaze","price_24h_change":-10.2414562587,"price_7d_change":-8.6596944836,"exponent":6},
    {"price":0.002691515,"denom":"ibc/9989AD6CCA39D1131523DB0617B50F6442081162294B4795E26746292467B525","symbol":"LIKE","main":true,"liquidity":144971.4486758845,"volume_24h":1078.9915428989,"volume_24h_change":-68.5234041714,"name":"LikeCoin","price_24h_change":-7.797031021,"price_7d_change":-11.6830556745,"exponent":9},
    {"price":0.482947864,"denom":"ibc/9BBA9A1C257E971E38C1422780CE6F0B0686F0A3085E2D61118D904BFE0F5F5E","symbol":"SOMM","main":true,"liquidity":353584.9946822373,"volume_24h":25451.516986382,"volume_24h_change":104.5138260338,"name":"Sommelier","price_24h_change":-8.4808570738,"price_7d_change":-6.2881643493,"exponent":6},
    {"price":0.0155776223,"denom":"ibc/9BCB27203424535B6230D594553F1659C77EC173E36D9CF4759E7186EE747E84","symbol":"DEC","main":true,"liquidity":79525.9869829901,"volume_24h":9769.0503030333,"volume_24h_change":282.5939346795,"name":"Decentr","price_24h_change":1.3672544531,"price_7d_change":4.6628906361,"exponent":6},
    {"price":0.9966991358,"denom":"ibc/9F9B07EF9AD291167CF5700628145DE1DEB777C2CFC7907553B24446515F6D0E","symbol":"gUSDC","main":false,"liquidity":202235.4059811727,"volume_24h":93682.5702067934,"volume_24h_change":71.7045030655,"name":"USD Coin","price_24h_change":-0.1051546454,"price_7d_change":-0.2621599583,"exponent":6},
    {"price":1.5907086551,"denom":"ibc/A0CC0CF735BFB30E730C70019D4218A1244FF383503FF7579C9201AB93CA9293","symbol":"XPRT","main":true,"liquidity":1974099.2898973865,"volume_24h":1203586.1628964646,"volume_24h_change":271.7248581825,"name":"Persistence","price_24h_change":-0.2264477605,"price_7d_change":13.0680316354,"exponent":6},
    {"price":0.0826941745,"denom":"ibc/B547DC9B897E7C3AA5B824696110B8E3D2C31E3ED3F02FF363DCBAD82457E07E","symbol":"XKI","main":true,"liquidity":487782.1331991199,"volume_24h":38272.3252638245,"volume_24h_change":78.607712532,"name":"Ki","price_24h_change":-10.4454963103,"price_7d_change":14.2947730962,"exponent":6},
    {"price":5.09671e-05,"denom":"ibc/B9E0A1A524E98BB407D3CED8720EFEFD186002F90C1B1B7964811DD0CCC12228","symbol":"HUAHUA","main":true,"liquidity":399328.1219326238,"volume_24h":55687.5691086306,"volume_24h_change":97.2858173005,"name":"Chihuahua","price_24h_change":-8.9910267876,"price_7d_change":-21.552431428,"exponent":6},
    {"price":0.0091238387,"denom":"ibc/BE1BB42D4BE3C30D50B68D7C41DB4DFCE9678E8EF8C539F6E6A9345048894FCC","symbol":"UST","main":true,"liquidity":576200.7062520474,"volume_24h":296395.1567858873,"volume_24h_change":43.0765813251,"name":"TerraUSD","price_24h_change":-29.5019583617,"price_7d_change":-86.5819131396,"exponent":6},
    {"price":8.6671078575,"denom":"ibc/C2A2E9CA95DDD4828B75124B5E27B8401C7D8493BC48353D418CBFC04565899B","symbol":"HOPE","main":false,"liquidity":9872.3022961071,"volume_24h":2195.2432061518,"volume_24h_change":394.654066225,"name":"Hope Galaxy","price_24h_change":-20.9324808061,"price_7d_change":-36.1031134342,"exponent":6},
    {"price":0.097735879,"denom":"ibc/CBA34207E969623D95D057D9B11B0C8B32B89A71F170577D982FDDE623813FFC","symbol":"MNTL","main":true,"liquidity":206097.4164663633,"volume_24h":24424.7476296686,"volume_24h_change":72.1258261737,"name":"AssetMantle","price_24h_change":-9.4465094814,"price_7d_change":-9.9924310235,"exponent":6},
    {"price":0.0506191157,"denom":"ibc/CE5BFF1D9BADA03BB5CCA5F56939392A761B53A10FBD03B37506669C3218D3B2","symbol":"HASH","main":false,"liquidity":4848.9684859163,"volume_24h":35.9558771337,"volume_24h_change":671.3119241433,"name":"Provenance","price_24h_change":-7.831516421,"price_7d_change":8.1567267244,"exponent":9},
    {"price":29950.0778133446,"denom":"ibc/D1542AA8762DB13087D8364F3EA6509FD6F009A34F00426AF9E4F9FA85CBBF1F","symbol":"axlWBTC","main":true,"liquidity":4174166.318685124,"volume_24h":411640.1187102118,"volume_24h_change":0.3983458566,"name":"Wrapped Bitcoin","price_24h_change":-5.205354291,"price_7d_change":2.8535974727,"exponent":8},
    {"price":1.0000053983,"denom":"ibc/D189335C6E4A68B513C10AB227BF1C1D38C746766278BA3EEB4FB14124F1D858","symbol":"axlUSDC","main":true,"liquidity":15965446.747710919,"volume_24h":6508837.170577442,"volume_24h_change":31.0063869917,"name":"USD Coin","price_24h_change":0.0005398278,"price_7d_change":0.0005398278,"exponent":6},
    {"price":0.0149975143,"denom":"ibc/D805F1DA50D31B96E4282C1D4181EDDFB1A44A598BFF5666F4B43E4B8BEA95A5","symbol":"BCNA","main":true,"liquidity":401196.425986926,"volume_24h":4829.1472067679,"volume_24h_change":14.2120123983,"name":"BitCanna","price_24h_change":-8.0584640878,"price_7d_change":-11.1439053454,"exponent":6},
    {"price":0.1789579253,"denom":"ibc/E6931F78057F7CC5DA0FD6CEF82FF39373A6E0452BF1FD76910B93292CF356C1","symbol":"CRO","main":true,"liquidity":3203870.262326096,"volume_24h":274609.3757751781,"volume_24h_change":-27.3486398883,"name":"Cronos","price_24h_change":-4.4485966312,"price_7d_change":3.6042286528,"exponent":8},
    {"price":0.0309329979,"denom":"ibc/E7B35499CFBEB0FF5778127ABA4FB2C4B79A6B8D3D831D4379C4048C238796BD","symbol":"VDL","main":true,"liquidity":24672.6829119752,"volume_24h":44.4238941979,"volume_24h_change":-27.3837803669,"name":"Vidulum","price_24h_change":-7.7046388111,"price_7d_change":-20.5655178418,"exponent":6},
    {"price":0.0129907483,"denom":"ibc/E97634A40119F1898989C2A23224ED83FDD0A57EA46B3A094E287288D1672B44","symbol":"GRAV","main":true,"liquidity":120331.8259392446,"volume_24h":3176.4718097224,"volume_24h_change":-63.7429052378,"name":"Graviton","price_24h_change":-5.9526032416,"price_7d_change":-2.2911852507,"exponent":6},
    {"price":1822.137513056,"denom":"ibc/EA1D43981D5C9A1C4AAEA9C23BB1D4FA126BA9BC7020A25E0AE4AA841EA25DC5","symbol":"axlWETH","main":true,"liquidity":5877079.885377711,"volume_24h":469927.7220574959,"volume_24h_change":-27.3405695558,"name":"Wrapped Ether","price_24h_change":-5.5194459615,"price_7d_change":-0.6527232498,"exponent":18},
    {"price":0.3751052257,"denom":"ibc/EA3E1640F9B1532AB129A571203A0B9F789A7F14BB66E350DCBFA18E1A1931F0","symbol":"CMDX","main":true,"liquidity":2283838.9429902197,"volume_24h":184088.9504237529,"volume_24h_change":-10.6062298123,"name":"Comdex","price_24h_change":-10.257640046,"price_7d_change":-14.7634274121,"exponent":6},
    {"price":0.022925866,"denom":"ibc/EA4C0A9F72E2CEDF10D0E7A9A6A22954DB3444910DB5BE980DF59B05A46DAD1C","symbol":"DSM","main":true,"liquidity":226846.3254858415,"volume_24h":4938.0046289356,"volume_24h_change":-63.5134102541,"name":"Desmos","price_24h_change":-8.6871977055,"price_7d_change":-24.1776514656,"exponent":6},
    {"price":0.9994444331,"denom":"ibc/F292A17CF920E3462C816CBE6B042E779F676CAB59096904C4C1C966413E3DF5","symbol":"gDAI","main":false,"liquidity":4916.6728869439,"volume_24h":427.6108812998,"volume_24h_change":-10.7550656385,"name":"Dai Stablecoin","price_24h_change":0.2571639218,"price_7d_change":6.2121626546,"exponent":18},
    {"price":0.0965716364,"denom":"ibc/F3FF7A84A73B62921538642F9797C423D2B4C4ACB3C7FCFFCE7F12AA69909C4B","symbol":"IXO","main":true,"liquidity":41407.9796277315,"volume_24h":2295.8429746441,"volume_24h_change":-76.1077552054,"name":"IXO","price_24h_change":-10.1104738377,"price_7d_change":77.6326559556,"exponent":6},
    {"price":6.5698699803,"denom":"ibc/F6B691D5F7126579DDC87357B09D653B47FDCE0A3383FF33C8D8B544FE29A8A6","symbol":"MARBLE","main":true,"liquidity":17353.0567078933,"volume_24h":745.7293651462,"volume_24h_change":-37.9984428469,"name":"Marble","price_24h_change":-8.8553083792,"price_7d_change":-5.3527170226,"exponent":3},
    {"price":1.6387690642,"denom":"ibc/F867AE2112EFE646EC71A25CD2DFABB8927126AC1E19F1BBF0FF693A4ECA05DE","symbol":"BAND","main":true,"liquidity":77864.2224715232,"volume_24h":7800.2675730213,"volume_24h_change":26.3006425233,"name":"Band Protocol","price_24h_change":-8.3966295511,"price_7d_change":3.8279851678,"exponent":6},
    {"price":2.34e-08,"denom":"ibc/FE2CD1E6828EC0FAB8AF39BAC45BC25B965BA67CCBC50C13A14BD610B0D1E2C4","symbol":"BOOT","main":true,"liquidity":153059.200618511,"volume_24h":6980.5258134917,"volume_24h_change":95.8835653735,"name":"Bostrom","price_24h_change":-9.3101003204,"price_7d_change":-14.0010515508,"exponent":0},
    {"price":948.2146871525,"denom":"uion","symbol":"ION","main":true,"liquidity":1297137.5102232387,"volume_24h":53800.7445178366,"volume_24h_change":-17.1734770431,"name":"Ion","price_24h_change":-6.6472721052,"price_7d_change":-22.8063714821,"exponent":6},
    {"price":1.2450629893,"denom":"uosmo","symbol":"OSMO","main":true,"liquidity":104011968.90202616,"volume_24h":20494663.87848946,"volume_24h_change":14.9717884495,"name":"Osmosis","price_24h_change":-7.6416257249,"price_7d_change":-14.8794050634,"exponent":6}
]